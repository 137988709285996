import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '@input-output-hk/front-end-core-components/components/Link'
import Box from '@material-ui/core/Box'

import { Fade } from './Transitions'
import Visible from './Visible'
import BgImage from './BgImage'
import Button from './Button'

const StyledButton = styled(Button)`
  margin: ${({ isLeft }) => (isLeft ? '0 auto 0 0' : '0 0 0 auto')};
  margin-top: 25px;

  @media (min-width: 1200px) {
    margin-top: 0px;
    opacity: 0;
  }
`

const Content = styled.div`
  transition: 0.3s ease-out;
  color: white;
  padding: 30px;
  z-index: 1;
  max-width: 100%;

  > h2,
  p {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    padding: 50px;
    max-width: 80%;
    transform: translateY(60px);
  }
`

const Wrapper = styled.div`
  overflow: hidden;

  @media (min-width: 1200px) {
    &:hover {
      ${Content} {
        transform: translateY(0px);
      }
      ${StyledButton} {
        opacity: 1;
        margin-top: 25px;
      }
    }
  }
`
const Inner = styled.div`
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ isLeft }) => (isLeft ? 'end' : 'flex-end')};
  text-align: ${({ isLeft }) => (isLeft ? 'left' : 'right')};
  height: 50vh;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: 0.3s ease-out;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }

  &:hover :after {
    opacity: 1;
  }

  @media (min-width: 600px) {
    height: 100vh;
  }
`

const StyledBgImage = styled(BgImage)`
  img {
    transition: all 0.3s ease-out, opacity 500ms ease 0s !important;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const UserCard = ({
  imageFluid,
  title,
  description,
  link,
  variant,
  height
}) => {
  return (
    <Wrapper>
      <Box height={height}>
        <StyledBgImage title='usercard' fluid={imageFluid} height={height}>
          <Inner isLeft={variant === 'left'}>
            <Content>
              <Visible
                render={(isVisible, ref) => (
                  <React.Fragment>
                    <Fade in={isVisible}>
                      <h2 ref={ref}> {title}</h2>
                    </Fade>
                    <Fade in={isVisible}>
                      <p>{description}</p>
                    </Fade>
                  </React.Fragment>
                )}
              />
              <Link
                tracking={{ label: `case-card_${title.toLowerCase()}` }}
                href={link}
              >
                <StyledButton variant='transparent' isLeft={variant === 'left'}>
                  Explore {title}
                </StyledButton>
              </Link>
            </Content>
          </Inner>
        </StyledBgImage>
      </Box>
    </Wrapper>
  )
}

UserCard.propTypes = {
  imageFluid: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  variant: PropTypes.oneOf([ 'left', 'right' ]),
  height: PropTypes.string
}
UserCard.defaultProps = {
  height: '100%'
}

export default UserCard
