import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const PartnersQuery = ({ render }) => (
  <StaticQuery
    query={graphql`
      query {
        partners: allFile(
          filter: { relativePath: { glob: "content/partners/*.md" } }
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                id
                name
                url
                logo {
                  childImageSharp {
                    fluid(maxWidth: 180) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      data.partners.nodes.sort(
        (a, b) =>
          a.childMarkdownRemark.frontmatter.id -
          b.childMarkdownRemark.frontmatter.id
      )
      return render(data)
    }}
  />
)

PartnersQuery.propTypes = {
  render: PropTypes.func.isRequired
}

export default PartnersQuery
