import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { analytics } from '@input-output-hk/front-end-core-libraries'

import BgImage from './BgImage'
import Button from './Button'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 112vh;
  padding: 30px;
  z-index: 1;

  h3,
  p,
  div {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 1;
  }

  h3 {
    margin-top: 10px;
  }

  p {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    margin: unset;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 1;
    bottom: 15px;

    padding: 14px 28px;
  }

  @media (min-width: 370px) {
    min-height: 100vh;
  }

  @media (min-width: 400px) {
    p {
      font-size: initial;
    }
  }

  @media (min-width: 900px) {
    padding: 50px;

    button {
      opacity: 0;
      transform: translateY(60px);
      bottom: 0px;

      padding: 20px 40px;
    }
  }
`

const Inner = styled.div`
  min-height: 112vh;
  max-height: 112vh;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    transition: ease-out 0.3s;
    z-index: 0;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.7);
  }

  @media (min-width: 370px) {
    min-height: 100vh;
    max-height: 100vh;
  }

  @media (min-width: 900px) {
  
    &:after {
      opacity: 0.65;
    }

    &:hover {
      button {
        opacity: 1;
        transform: translateY(0);
      }

      &:after {
        opacity: 1;
      }
    }
  }
`

const FlexText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;


  h4,
  p {
    margin: 0 12px 0 0;
  }
`
const StyledBgImage = styled(BgImage)`
  min-height: 112vh;
  max-height: 112vh;

  > div {
    min-height: 100vh;
  }
  img {
    transition: all 0.3s ease-out, opacity 500ms ease 0s !important;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  @media (min-width: 370px) {
    min-height: 100vh;
    max-height: 100vh;
  }
`

const CaseCard = ({
  imageFluid,
  title,
  description,
  firstData,
  firstDataDesc,
  secondData,
  secondDataDesc,
  thirdData,
  thirdDataDesc,
  onBtnClickHandler
}) => {
  return (
    <Box minHeight='100vh'>
      <StyledBgImage title='casecard' fluid={imageFluid}>
        <Inner>
          <Content>
            <div>
              <p>Case Study</p>
              <h3> {title}</h3>
              <p>{description}</p>
              <br />
              <FlexText>
                <h4>{firstData}</h4>
                <p>{firstDataDesc}</p>
              </FlexText>
              <FlexText>
                <h4>{secondData}</h4>
                <p>{secondDataDesc}</p>
              </FlexText>
              <FlexText>
                <h4>{thirdData}</h4>
                <p>{thirdDataDesc}</p>
              </FlexText>
              <br />
              <p>Results after just 3 months</p>
            </div>
            <Button
              variant='transparent'
              onClick={(e) => {
                analytics.click({
                  category: 'case_card',
                  label: { title },
                  event: e
                })
                onBtnClickHandler(e)
              }}
            >
              Want to know more?
            </Button>
          </Content>
        </Inner>
      </StyledBgImage>
    </Box>
  )
}

CaseCard.propTypes = {
  imageFluid: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  firstData: PropTypes.string,
  firstDataDesc: PropTypes.string,
  secondData: PropTypes.string,
  secondDataDesc: PropTypes.string,
  thirdData: PropTypes.string,
  thirdDataDesc: PropTypes.string,
  onBtnClickHandler: PropTypes.func.isRequired
}

export default CaseCard
