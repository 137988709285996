import React, { useRef, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid, Box, useMediaQuery } from '@material-ui/core'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import ContactSection from '../components/ContactSection'
import PartnerSection from '../components/PartnerSection'
import ImageGridSection from '../components/ImageGridSection'
import CaseSection from '../components/CaseSection'
import OptImages from '../components/OptImages'
import UserCard from '../components/UserCard'
import Quote from '../components/Quote'
import NumbersCard from '../components/NumbersCard'
import { Fade } from '../components/Transitions'
import Visible from '../components/Visible'
// import IconBrandieSvg from '../../resources/images/svg/inline/icon_brandie.svg'

import IndexPageQuery from '../queries/IndexPageQuery'
import ImagesQuery from '../queries/ImagesQuery'

import 'react-multi-carousel/lib/styles.css'

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))

  const contactSectionRef = useRef(null)
  const scrollToRef = (ref) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'PageView')
      }
    }
  }, [])

  return (
    <IndexPageQuery
      render={(content) => (
        <Layout>
          <Box marginTop={0} marginBottom={0}>
            <Hero
              ecenter1={<h1>Shoot it.</h1>}
              ecenter2={<h1>Share it.</h1>}
              ebottom={<p>Earn Rewards!</p>}
              link='Request Demo'
              onBtnClickHandler={(e) => {
                scrollToRef(contactSectionRef)
              }}
            />

            <Section centered>
              <Grid
                container
                spacing={0}
                justify='center'
                alignItems='center'
                direction='row'
              >
                <Grid item xs={12} sm={6}>
                  <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                    <Visible
                      render={(isVisible, ref) => (
                        <Fade in={isVisible}>
                          <Box ref={ref}>
                            <h2>This is Brandie</h2>
                            <p>
                              We Democratize marketing by giving the benefit of
                              the top 1% of social media users to the other 99%.
                            </p>
                            <p>
                              For Brands, this means that you can harness the
                              power of Word of Mouth on social media - at scale.
                            </p>
                            <p>
                              For Users, this means that you can connect with
                              your favorite brands and be rewarded for the
                              content that you share.
                            </p>
                          </Box>
                        </Fade>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={-4} mt={0}>
                    <OptImages.IphoneLogin
                      style={{ height: '100%', transform: 'scale(1.15)' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Section>

            <ImagesQuery
              render={(images) => (
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <Section minHeight={smUp ? '100vh' : '50vh'}>
                      <UserCard
                        imageFluid={images.ambassador.childImageSharp.fluid}
                        title='Ambassadors'
                        description='Join the movement and become an ambassador for your favorite brand today!'
                        link='/ambassadors'
                        variant='right'
                        height={smUp ? '100vh' : '50vh'}
                      />
                    </Section>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Section minHeight={smUp ? '100vh' : '50vh'}>
                      <UserCard
                        imageFluid={images.brand.childImageSharp.fluid}
                        title='Brands'
                        description='Join the world’s leading brands in turning your advocates into a powerful marketing force.'
                        link='/brands'
                        variant='left'
                        height={smUp ? '100vh' : '50vh'}
                      />
                    </Section>
                  </Grid>
                </Grid>
              )}
            />

            <Section
              centered
              py={5}
              px={smUp ? 8 : 2}
              gradient={[ '#66BF7E', '#5CB774' ]}
              flexDirection='column'
              position='relative'
              overflow='hidden'
              color='white'
              // svg={IconBrandieSvg}
              // svgProps={{ viewBox: '0 0 152.34 259.7' }}
              textAlign='center'
            >
              <Visible
                render={(isVisible, ref) => (
                  <Fade in={isVisible}>
                    <Box
                      maxWidth='700px'
                      display='block'
                      margin='0 auto'
                      marginBottom='45px'
                      textAlign='center'
                      color='white'
                    >
                      <h2 ref={ref}>Engage with Brandie's exclusive network</h2>
                    </Box>
                  </Fade>
                )}
              />
              <Grid container spacing={2} justify='center' alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <NumbersCard
                    number={76287}
                    subTitle='Content creators activated'
                    timeout={0}
                    image={<OptImages.EmojiFollower />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumbersCard
                    number={128522715}
                    subTitle='Consumers reached with campaigns'
                    timeout={700}
                    image={<OptImages.EmojiStats />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumbersCard
                    number={100}
                    suffix='%'
                    subTitle='Authentic and trusted UGC shared'
                    image={<OptImages.EmojiMobile />}
                    timeout={1400}
                  />
                </Grid>
              </Grid>
            </Section>

            <PartnerSection />

            <CaseSection
              onCaseBtnClick={(e) => scrollToRef(contactSectionRef)}
            />

            <ImagesQuery
              render={(images) => (
                <Section centered>
                  <Box my={10} mx={smUp ? 6.5 : 1}>
                    <Grid
                      container
                      spacing={0}
                      justify='center'
                      alignItems='flex-start'
                      height='100%'
                    >
                      <Grid item sm='auto' md={4}>
                        <Visible
                          render={(isVisible, ref) => (
                            <Fade in={isVisible} timeout={0}>
                              <Box ref={ref}>
                                <Quote
                                  imageFluid={
                                    images.quote1.childImageSharp.fluid
                                  }
                                  quote='Brandie can offer brands a massive opportunity to customise their messaging more than ever before, engaging an army of influencers, instead of just a handful.'
                                />
                              </Box>
                            </Fade>
                          )}
                        />
                      </Grid>
                      <Grid item sm='auto' md={4}>
                        <Visible
                          render={(isVisible, ref) => (
                            <Fade in={isVisible} timeout={smUp ? 500 : 0}>
                              <Box ref={ref}>
                                <Quote
                                  imageFluid={
                                    images.quote3.childImageSharp.fluid
                                  }
                                  quote="Brandie is a Sweden-based crowd marketing platform that is also the world's first social media loyalty program."
                                />
                              </Box>
                            </Fade>
                          )}
                        />
                      </Grid>
                      <Grid item sm='auto' md={4}>
                        <Visible
                          render={(isVisible, ref) => (
                            <Fade in={isVisible} timeout={smUp ? 1000 : 0}>
                              <Box ref={ref}>
                                <Quote
                                  imageFluid={
                                    images.quote2.childImageSharp.fluid
                                  }
                                  quote='Brandie have developed a disruptive model where consumers promote the brand they love on social media...'
                                />
                              </Box>
                            </Fade>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Section>
              )}
            />

            <ImageGridSection />

            <Box>
              <Grid container spacing={0} justify='center'>
                {/* <Grid container spacing={0} justify='center' alignItems='strech'> */}
                <Grid item xs={12} sm={6}>
                  <OptImages.WhatWeDo style={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={6} container alignItems='center'>
                  <Grid item>
                    <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                      <Visible
                        render={(isVisible, ref) => (
                          <React.Fragment>
                            <Fade in={isVisible}>
                              <React.Fragment>
                                <div
                                  ref={ref}
                                  style={{
                                    height: '120px',
                                    width: '120px',
                                    background: 'white',
                                    borderRadius: '100px',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'left',
                                    marginBottom: '10px'
                                  }}
                                >
                                  <OptImages.EmojiPeace
                                    style={{ width: '90px' }}
                                  />
                                </div>
                                <h2>What we do</h2>
                              </React.Fragment>
                            </Fade>
                            <Fade in={isVisible}>
                              <p>
                                Brandie’s Crowd Marketing technology was
                                researched and developed in Sweden by behavioral
                                scientists and engineers. The goal has been to
                                carefully convert customers into brand
                                ambassadors, at scale, and be able to give them
                                meaningful rewards for their loyalty. With
                                Machine Learning and Artificial Intelligence, we
                                make it possible for brands to implement
                                successful & smart UGC campaigns.
                                <br />
                                <br />
                                Brandie has analyzed over half a million
                                user-generated photos across the globe to
                                perfect customer advocacy and drive UGC on
                                social media. This massive amount of knowledge
                                on customer habits and photo-sharing behavior
                                has given us a proven track record of guiding
                                our clients to success. Brandie's unique
                                technology has been tailored around scalable AI
                                solutions to enable social media marketing
                                campaigns at scale.
                                <br />
                                <br />
                                Led by serial entrepreneurs and former
                                executives from companies like IKEA, Oriflame,
                                Gionee, and H&M. Brandie is built on the bedrock
                                of key insights from years of marketing
                                expertise in both traditional as well as digital
                                marketing channels.
                              </p>
                            </Fade>
                          </React.Fragment>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <ContactSection ref={contactSectionRef} />
          </Box>
        </Layout>
      )}
    />
  )
}
