import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import CaseCard from '../components/CaseCard'
import ImagesQuery from '../queries/ImagesQuery'
import Section from './Section'

const carouselres = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 960
    },
    items: 3,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: {
      max: 600,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 960,
      min: 600
    },
    items: 2,
    partialVisibilityGutter: 30
  }
}
const useStyles = makeStyles((theme) => ({
  carousel: {},
  container: {},
  item: {},
  slider: {},
  dotlist: {
    '& .react-multi-carousel-dot button': {
      border: 'none',
      'background-color': 'white',
      opacity: '0.5',
      'margin-bottom': '16px',
      'margin-right': '8px'
    },
    '& .react-multi-carousel-dot--active button': {
      'background-color': 'white',
      opacity: '1'
    }
  }
}))
const CaseSection = ({ onCaseBtnClick }) => {
  const classes = useStyles()
  return (
    <ImagesQuery
      render={(data) => (
        <Section centered m={0}>
          <Grid container spacing={0} justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={6000}
                centerMode={false}
                className={classes.carousel}
                containerClass={classes.container}
                customTransition='all 0.7s ease-in-out'
                dotListClass={classes.dotlist}
                draggable
                focusOnSelect={false}
                infinite
                itemClass={classes.item}
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                showDots
                sliderClass={classes.slider}
                slidesToSlide={1}
                swipeable
                transitionDuration={700}
                responsive={carouselres}
              >
                <CaseCard
                  imageFluid={data.case1.childImageSharp.fluid}
                  title='Air India'
                  description='With the launch of the new direct flight between Delhi - Stockholm, Air India utilized Brandie to build awareness and inspiration about their new route. Thousands of customers were transformed into vocal advocates by sharing their desires to travel to this destination with Air India to their social media.'
                  firstData='3000+'
                  firstDataDesc='Customers sharing content'
                  secondData='4000+'
                  secondDataDesc='Social media posts'
                  thirdData='4,3M+'
                  thirdDataDesc='Reach on Instagram alone'
                  link='Want to know more?'
                  onBtnClickHandler={onCaseBtnClick}
                />
                <CaseCard
                  imageFluid={data.case2.childImageSharp.fluid}
                  title='Fastrack'
                  description="To capture the consumers' love for the brand, Fastrack used Brandie to drive more UGC around their coming events during Sunburn. By giving out perks and incentives their customers started to create and share more content than ever before."
                  firstData='1400+'
                  firstDataDesc='Customers sharing content'
                  secondData='2600+'
                  secondDataDesc='Social media posts'
                  thirdData='2,2M+'
                  thirdDataDesc='Reach on Instagram alone'
                  link='Want to know more?'
                  onBtnClickHandler={onCaseBtnClick}
                />
                <CaseCard
                  imageFluid={data.case3.childImageSharp.fluid}
                  title='PUMA'
                  description="Puma utilizes Brandie's crowd marketing platform to build a community of vocal brand ambassadors. With Brandie, they managed to transform passive followers into active brand advocates."
                  firstData='2300+'
                  firstDataDesc='Customers sharing content'
                  secondData='4000+'
                  secondDataDesc='Social media posts'
                  thirdData='4,2M+'
                  thirdDataDesc='Reach on Instagram alone'
                  link='Want to know more?'
                  onBtnClickHandler={onCaseBtnClick}
                />
                <CaseCard
                  imageFluid={data.case4.childImageSharp.fluid}
                  title='Raw Pressery'
                  description='Raw Pressery built a community of vocal brand ambassadors through Brandie, to trigger a viral network effect of recommendations. Thousands of people were sharing photos when enjoying their healthy juices, and inspired friends and family to do the same.'
                  firstData='1500+'
                  firstDataDesc='Customers sharing content'
                  secondData='200%'
                  secondDataDesc='increase in social media conversations'
                  thirdData='9,1M+'
                  thirdDataDesc='Reach on Instagram alone'
                  link='Want to know more?'
                  onBtnClickHandler={onCaseBtnClick}
                />
              </Carousel>
            </Grid>
          </Grid>
        </Section>
      )}
    />
  )
}

CaseSection.propTypes = {
  onCaseBtnClick: PropTypes.func.isRequired
}

export default CaseSection
