import React from 'react'
import Carousel from 'react-multi-carousel'
import { Grid, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Link from '@input-output-hk/front-end-core-components/components/Link'
import styled from 'styled-components'

import Img from 'gatsby-image'

import PartnersQuery from '../queries/PartnersQuery'
import Section from '../components/Section'
import Visible from '../components/Visible'
import { Fade } from '../components/Transitions'

const StyledPartners = styled.div`
  div {
    overflow: visible;
  }
`

const carouselres = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 960
    },
    items: 5,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: {
      max: 600,
      min: 0
    },
    items: 2,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 960,
      min: 600
    },
    items: 4,
    partialVisibilityGutter: 30
  }
}
const useStyles = makeStyles((theme) => ({
  carousel: {},
  container: {
    '& ul': {
      display: 'flex',
      'align-items': 'center',
      height: '220px'
    }
  },
  item: {
    transition: '0.3s ease-out',
    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  slider: {},
  dotlist: {
    '& li button': {
      'border-color': theme.colors.primary.main
    }
  }
}))
const PartnerSection = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  // const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  return (
    <Section
      centered
      textAlign='center'
      my={smUp ? 0 : 4}
      minHeight={smUp ? '100vh' : '70vh'}
    >
      <Grid
        container
        spacing={smUp ? 10 : 1}
        justify='center'
        alignItems='center'
      >
        <Grid item xs={12}>
          <Visible
            render={(isVisible, ref) => (
              <Fade in={isVisible}>
                <h2 ref={ref}>A selection of our partners</h2>
              </Fade>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledPartners>
            <PartnersQuery
              render={(data) => (
                <Carousel
                  additionalTransfrom={0}
                  arrows={false}
                  autoPlay
                  autoPlaySpeed={1}
                  centerMode={false}
                  className={classes.carousel}
                  containerClass={classes.container}
                  customTransition='all 5s linear'
                  dotListClass={classes.dotlist}
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass={classes.item}
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  showDots={false}
                  sliderClass={classes.slider}
                  slidesToSlide={1}
                  swipeable
                  transitionDuration={5000}
                  responsive={carouselres}
                >
                  {data.partners.nodes.map((partner, idx) => (
                    <Box
                      key={`${idx}-${partner.childMarkdownRemark.frontmatter.name}`}
                      mx={smUp ? 5 : 2}
                    >
                      <Grid
                        container
                        spacing={0}
                        justify='center'
                        alignItems='center'
                      >
                        <Grid item xs={12}>
                          <Link
                            tracking={{ label: 'partner-section_partner' }}
                            href={partner.childMarkdownRemark.frontmatter.url}
                            width='auto'
                          >
                            <Img
                              fluid={
                                partner.childMarkdownRemark.frontmatter.logo
                                  .childImageSharp.fluid
                              }
                            />
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Carousel>
              )}
            />
          </StyledPartners>
        </Grid>
      </Grid>
    </Section>
  )
}

PartnerSection.propTypes = {}

export default PartnerSection
