import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Grow } from '@material-ui/core'

import { useCountUp } from 'react-countup'
import Visible from '../components/Visible'

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 100px;
  width: 140px;
  height: 140px;
  > * {
    width: 50%;
  }
`
const StyledBox = styled(Box)`
  h2 {
    margin-top: 30px;
    color: ${(props) => props.theme.colors.primary.main};
    color: white;
    div {
      width: 22px;
      display: inline-block;
      text-align: center;
    }
  }
  p {
    color: white;
    margin-top: 0;
    text-align: center;
  }

  @media (min-width: 600px) {
    h2 {
      div {
        width: 39px;
      }
    }
  }
`

const NumbersCard = ({ number, timeout, subTitle, suffix, image }) => {
  const { countUp, start /* pauseResume, reset, update */ } = useCountUp({
    start: Number(number * 0.1).toFixed(),
    end: number,
    delay: 1000 * timeout,
    useEasing: true,
    startOnMount: false,
    duration: 3
    // onReset: () => console.log('Resetted!'),
    // onUpdate: () => console.log('Updated!'),
    // onPauseResume: () => console.log('Paused or resumed!'),
    // onStart: ({ pauseResume }) => console.log(pauseResume),
    // onEnd: ({ pauseResume }) => console.log(pauseResume)
  })

  const onChangeHandler = (isVisible) => isVisible && start()

  return (
    <Visible
      onChange={onChangeHandler}
      render={(isVisible, ref) => (
        <Grow in={isVisible} timeout={1000 + timeout}>
          <StyledBox
            ref={ref}
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <Circle>{image}</Circle>
            <h2>
              {countUp
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .split('')
                .map((char, idx) =>
                  char !== ',' ? <div key={`${char}-${idx}`}>{char}</div> : ','
                )}
              {suffix && suffix}
            </h2>
            <p>{subTitle}</p>
          </StyledBox>
        </Grow>
      )}
    />
  )
}

NumbersCard.propTypes = {
  number: PropTypes.number,
  suffix: PropTypes.string,
  timeout: PropTypes.number,
  subTitle: PropTypes.string,
  image: PropTypes.node
}

NumbersCard.defaultProps = {
  timeout: 0
}

export default NumbersCard
