import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Box from '@material-ui/core/Box'
import QuoteSvg from '../../resources/images/svg/inline/quote.svg'

const StyledBox = styled(Box)`
  margin: 4rem 35px 4rem 2.5rem;
  max-width: 400px;
  position: relative;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-left: 25px;
  background: #f8f8f8;
  padding: 30px;
  border-radius: 20px;

  p {
    font-style: italic;
    margin: 0;
    padding-right: 5px;
  }
`
const StyledQuoteSvg = styled(QuoteSvg)`
  position: absolute;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  path {
    fill: ${(props) => props.theme.colors.primary.main};
  }
`
const Logo = styled(Img)`
  height: 50px;
  width: 145px;
  position: absolute !important;
  top: -26px;
  right: 16px;
  border-radius: 100px;
  // box-shadow: 0px 3px 20px rgba(0,0,0,0.07);
  background-color: white;
  z-index: 1;
  border: 2px solid #f8f8f8;

  img {
    padding: 0 10px;
  }
`

const Quote = ({ imageFluid, quote }) => {
  return (
    <StyledBox>
      <StyledQuoteSvg />
      <Content>
        <p>{quote}</p>
      </Content>
      <Logo imgStyle={{ objectFit: 'contain' }} fluid={imageFluid} />
    </StyledBox>
  )
}

Quote.propTypes = {
  imageFluid: PropTypes.object,
  quote: PropTypes.string
}

export default Quote
